const getZeroTimeDate = (date: string) => new Date(new Date(date).setHours(0, 0, 0, 0))

export const MIN_FIT_DATE = getZeroTimeDate('2012/01/01')
export const MIN_NO_SUBSIDY_DATE = getZeroTimeDate('2016/04/01')
export const MIN_FIP_DATE = getZeroTimeDate('2022/04/01')
export const MAX_SOLAR_ASSET_DATE = getZeroTimeDate('2064/12/31')
export const BATTERY_OPERATION_MIN_START_DATE = getZeroTimeDate('2011/04/01')
export const BATTERY_OPERATION_MAX_START_DATE = getZeroTimeDate('2066/12/31')
export const LOAD_ENTITY_OPERATION_MIN_START_DATE = getZeroTimeDate('1980/01/01')
export const LOAD_ENTITY_OPERATION_MAX_START_DATE = getZeroTimeDate('2066/12/31')
export const MIN_PPA_START_DATE = getZeroTimeDate('2016/04/01')
export const MAX_PPA_START_DATE = getZeroTimeDate('2049/12/31')
export const SPV_FINANCIALS_MIN_DATE = getZeroTimeDate('2000/01/01')
export const SPV_FINANCIALS_MAX_DATE = getZeroTimeDate('2065/12/31')
export const CAPEX_MAX_DATE = getZeroTimeDate('2099/12/31')

import { forwardRef } from 'react'
import { DialogProps as AriaDialogProps } from 'react-aria-components'
import { DialogContents } from './DialogContents'
import { ControlledDialogProps } from './types'

export const ControlledDialog = forwardRef<
  HTMLDivElement,
  ControlledDialogProps & {
    buttons: AriaDialogProps['children']
    width?: string
  }
>(({ isOpen, onOpenChange, ...props }, ref) => {
  const close = () => onOpenChange(false)

  return (
    <DialogContents
      {...props}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      close={close}
      ref={ref}
    />
  )
})

ControlledDialog.displayName = 'ControlledDialog'

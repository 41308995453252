import { GetState, SetState } from 'zustand'
import {
  createAnalyticsPermanentSlice,
  createAnalyticsSlice,
  createAssetViewSlice,
  createAuthenticationSlice,
  createAuthorizationSlice,
  createBalancingGroupsSlice,
  createBgCreationDataSlice,
  createChainActionsSlice,
  createContactSupportSlice,
  createDemandDataUploadSlice,
  createEditAssetPlanSlice,
  createInviteVerificationSlice,
  createLastUpdateSlice,
  createMeterDataUploadSlice,
  createMonitoringDataUploadSlice,
  createOperationUploadsSlice,
  createPpaCreationDataSlice,
  createPpasListSlice,
  createReportsSlice,
  createScenariosListSlice,
  createSolarAssetsListSlice,
  createSpvCreationDataSlice,
  createSpvsListSlice,
  createTradingSlice,
  createUserPreferencesSlice,
  createWorkspaceSlice,
} from '../slices'
import { createSettingsReturnUrlSlice } from '../slices/settings-return-url'

export const createPersistedStoreSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createAssetViewSlice(set, get),
  ...createAuthenticationSlice(set, get),
  ...createBalancingGroupsSlice(set, get),
  ...createChainActionsSlice(set, get),
  ...createAnalyticsPermanentSlice(set, get),
  ...createPpasListSlice(set, get),
  ...createReportsSlice(set, get),
  ...createScenariosListSlice(set, get),
  ...createSolarAssetsListSlice(set, get),
  ...createSpvsListSlice(set, get),
  ...createTradingSlice(set, get),
  ...createUserPreferencesSlice(set, get),
  ...createWorkspaceSlice(set, get),
  ...createInviteVerificationSlice(set, get),
  ...createContactSupportSlice(set, get),
})

export type TensorCloudPersistedSlice = ReturnType<typeof createPersistedStoreSlice>

export const createSessionStoreSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createAnalyticsSlice(set, get),
  ...createLastUpdateSlice(set, get),
  ...createBgCreationDataSlice(set, get),
  ...createPpaCreationDataSlice(set, get),
  ...createSpvCreationDataSlice(set, get),
  ...createOperationUploadsSlice(set, get),
  ...createMeterDataUploadSlice(set, get),
  ...createMonitoringDataUploadSlice(set, get),
  ...createDemandDataUploadSlice(set, get),
  ...createAuthorizationSlice(set, get),
  ...createEditAssetPlanSlice(set, get),
  ...createSettingsReturnUrlSlice(set, get),
})

export type TensorCloudSessionStoreSlice = ReturnType<typeof createSessionStoreSlice>
